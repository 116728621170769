import { MyToastrService } from './../../../../_services/toastr.service';
import { BaseRequestService } from 'app/_services/base.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import {AuthService} from '../../../../core/auth/auth.service';
import {ErrorService} from '../../../../_services/error.service';
import { environment } from 'environments/environment';

@Component({
    selector     : 'modern-layout',
    templateUrl  : './modern.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy
{
    logo = `${environment.assets}assets/images/logo/logo.png`;
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    dealerNavigation: FuseNavigationItem[] = [
    {
        id   : 'apos',
        title: 'LIST OF APOS',
        type : 'basic',
        icon : 'heroicons_outline:device-mobile',
        link : '/pages/apos'
    },
    {
        id   : 'dealer-transactions',
        title: 'TRANSACTION',
        type : 'basic',
        icon : 'heroicons_outline:currency-rupee',
        link : '/pages/dealer-transactions'
    },
    {
        id   : 'manager-pin-generation',
        title: 'CONFIGURATION',
        type : 'basic',
        icon : 'heroicons_outline:cog',
        link : '/pages/manager-pin-generation'
    },
    {
        id   : 'support',
        title: 'SUPPORT',
        type : 'basic',
        icon : 'heroicons_outline:badge-check',
        link : '/pages/support'
    }
];
    supportNavigation: FuseNavigationItem[] = [
        {
            id   : 'transactions',
            title: 'TRANSACTIONS',
            type : 'basic',
            icon : 'heroicons_outline:currency-rupee',
            link : '/pages/transactions'
        },
        {
            id   : 'support',
            title: 'SUPPORT',
            type : 'basic',
            icon : 'heroicons_outline:badge-check',
            link : '/pages/support'
        },
    ];
    stateNavigation: FuseNavigationItem[] = [
        {
            id   : 'dealers',
            title: 'CUSTOMERS',
            type : 'basic',
            icon : 'heroicons_outline:office-building',
            link : '/pages/dealers'
        },
        {
            id   : 'apos',
            title: 'POS',
            type : 'basic',
            icon : 'heroicons_outline:device-mobile',
            link : '/pages/apos'
        },
        {
            id   : 'transactions',
            title: 'TRANSACTIONS',
            type : 'basic',
            icon : 'heroicons_outline:currency-rupee',
            link : '/pages/transactions'
        },
    ];
    regionNavigation: FuseNavigationItem[] = [
        {
            id   : 'dealers',
            title: 'CUSTOMERS',
            type : 'basic',
            icon : 'heroicons_outline:office-building',
            link : '/pages/dealers'
        },
        {
            id   : 'apos',
            title: 'POS',
            type : 'basic',
            icon : 'heroicons_outline:device-mobile',
            link : '/pages/apos'
        },
        {
            id   : 'transactions',
            title: 'TRANSACTIONS',
            type : 'basic',
            icon : 'heroicons_outline:currency-rupee',
            link : '/pages/transactions'
        },
    ];
    territoryNavigation: FuseNavigationItem[] = [
        {
            id   : 'dealers',
            title: 'CUSTOMERS',
            type : 'basic',
            icon : 'heroicons_outline:office-building',
            link : '/pages/dealers'
        },
        {
            id   : 'apos',
            title: 'POS',
            type : 'basic',
            icon : 'heroicons_outline:device-mobile',
            link : '/pages/apos'
        },
        {
            id   : 'transactions',
            title: 'TRANSACTIONS',
            type : 'basic',
            icon : 'heroicons_outline:currency-rupee',
            link : '/pages/transactions'
        },
        {
            id   : 'support',
            title: 'SUPPORT',
            type : 'basic',
            icon : 'heroicons_outline:badge-check',
            link : '/pages/support'
        },
    ];
    raVendorNavigation: FuseNavigationItem[] = [
        {
            id   : 'dealers',
            title: 'CUSTOMERS',
            type : 'basic',
            icon : 'heroicons_outline:office-building',
            link : '/pages/dealers'
        },
        {
            id   : 'apos',
            title: 'POS',
            type : 'basic',
            icon : 'heroicons_outline:device-mobile',
            link : '/pages/apos'
        }
    ];
    adminNavigation: FuseNavigationItem[] = [
        {
            id   : 'dealers',
            title: 'CUSTOMERS',
            type : 'basic',
            icon : 'heroicons_outline:office-building',
            link : '/pages/dealers'
        },
        {
            id   : 'apos',
            title: 'POS',
            type : 'basic',
            icon : 'heroicons_outline:device-mobile',
            link : '/pages/apos'
        },
        {
            id   : 'transactions',
            title: 'TRANSACTIONS',
            type : 'basic',
            icon : 'heroicons_outline:currency-rupee',
            link : '/pages/transactions'
        },
        {
            id   : 'admin-config',
            title: 'CONFIGURATION',
            type : 'basic',
            icon : 'heroicons_outline:cog',
            link : '/pages/admin-config'
        },
        {
            id   : 'support',
            title: 'SUPPORT',
            type : 'basic',
            icon : 'heroicons_outline:badge-check',
            link : '/pages/support'
        },
        {
            id   : 'users',
            title: 'USERS',
            type : 'basic',
            icon : 'heroicons_outline:users',
            link : '/pages/users'
        }
    ];
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router, private _authService: AuthService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService, private baseService: BaseRequestService,
        private toast: MyToastrService, public eS: ErrorService,
    )
    {
        console.log('m-layout');
        if (this._authService._logginType.toLowerCase() === 'vms_admin') {
            this.navigation = {
                compact: this.adminNavigation,
                default: this.adminNavigation,
                futuristic: this.adminNavigation,
                horizontal:this.adminNavigation
            };
        } else if (this._authService._logginType.toLowerCase() === 'vms_territory') {
            this.navigation = {
                compact: this.territoryNavigation,
                default: this.territoryNavigation,
                futuristic: this.territoryNavigation,
                horizontal:this.territoryNavigation
            };
        } else if (this._authService._logginType.toLowerCase() === 'vms_state') {
            this.navigation = {
                compact: this.stateNavigation,
                default: this.stateNavigation,
                futuristic: this.stateNavigation,
                horizontal:this.stateNavigation
            };
        } else if (this._authService._logginType.toLowerCase() === 'vms_region') {
            this.navigation = {
                compact: this.regionNavigation,
                default: this.regionNavigation,
                futuristic: this.regionNavigation,
                horizontal:this.regionNavigation
            };
        } else if (this._authService._logginType.toLowerCase() === 'vms_ravendor') {
            this.navigation = {
                compact: this.raVendorNavigation,
                default: this.raVendorNavigation,
                futuristic: this.raVendorNavigation,
                horizontal:this.raVendorNavigation
            };
        } else if (this._authService._logginType.toLowerCase() === 'vms_manager' || this._authService._logginType.toLowerCase() === 'ro_dealer') {
            this.navigation = {
                compact: this.dealerNavigation,
                default: this.dealerNavigation,
                futuristic: this.dealerNavigation,
                horizontal:this.dealerNavigation
            };
        } else if (this._authService._logginType.toLowerCase() === 'vms_support') {
            this.navigation = {
                compact: this.supportNavigation,
                default: this.supportNavigation,
                futuristic: this.supportNavigation,
                horizontal:this.supportNavigation
            };
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
       /* this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });*/

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
