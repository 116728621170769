import { MyToastrService } from './../../../_services/toastr.service';
import { BaseRequestService } from 'app/_services/base.service';
// import { Base64 } from 'crypto-js/enc-base64';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import {AuthService} from '../../../core/auth/auth.service';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;
    role: any = '';
    isAdmin = false; isDealer = false; isRAVendor = false; isTerritory = false;
    userRoles: any = ['DSM', 'R0_MANAGER', 'RO_DEALER', 'ADMIN', 'RA_VENDOR', 'SUPPORT', 'TERRITORY', 'STATE', 'NA', 'REGION', 'FINANCE'];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router, private toast: MyToastrService,
        private _userService: UserService,
        private _authService: AuthService, private baseService: BaseRequestService,
    )
    {
        console.log('user');
        this.isRAVendor = (_authService._logginType.toLowerCase() === 'ra_vendor' || _authService._logginType.toLowerCase() === 'vms_ravendor');
        this.isTerritory = (_authService._logginType.toLowerCase() === 'vms_territory');
        this.isAdmin = (_authService._logginType.toLowerCase() === 'vms_admin'
        || _authService._logginType.toLowerCase() === 'vms_territory' || _authService._logginType.toLowerCase() === 'admin');
        this.isDealer = (_authService._logginType.toLowerCase() === 'ro_dealer'
         || _authService._logginType.toLowerCase() === 'vms_dealer');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        setTimeout(() => {
            const resu = localStorage.getItem('resu');
            if (resu && resu !== null) {
                try {
                    this._authService._user = JSON.parse(resu);
                    if (this._authService._user) {
                        this.user = this._authService._user;
                    }
                    // console.log(this._authService._user);
                } catch (e) {
                    console.log(e);
                }
            } else {
                const url = (this._authService._logginType.toLowerCase() === 'vms_dealer'
                 || this._authService._logginType.toLowerCase() === 'ro_dealer')
                  ? '/api/ro_users/me' : '/api/unifiedbilling_user/me';
                this.baseService.doRequest(this.baseService.API +url, 'get').subscribe((result: any) => {
                    if (result.status === 'ok') {
                        result.msg.name = result.msg.firstName + ' ' + result.msg.lastName;
                        this._authService._user = result.msg;
                        this._authService._logginType = this.baseService.roleHash[result.msg.role];
                        this.user = result.msg;
                        this.role = this.userRoles[result.msg.role];
                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    } else {
                        this.toast.sToast('error', 'Session expired!'); this.signOut();
                    }
                });
            }
        });

        // console.log(this._authService._user);
        /* this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            }); */
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void
    {
        // Return if user is not available
        if ( !this.user )
        {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/sign-out']);
    }
}
