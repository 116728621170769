import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {BaseRequestService} from './base.service';
import {MyToastrService} from './toastr.service';

@Injectable({providedIn: 'root'})
export class CommonService {
  previousLink = '';
  cHash: any;
  companiesList: any = [];
  modifiedDiscoverItems: any;
  patchingStatus = false;
  isEulaAccepted = false;
    // eslint-disable-next-line @typescript-eslint/naming-convention
  Objectkeys = Object.keys;
  complianceMaster: any = {};
  complianceList: any = [];
  securityMatrix: any = {};
  complianceMatrix: any = {};
  angularEditorChange: Subject<object> = new Subject<object>();
  bwListChange: Subject<object> = new Subject<object>();
  captureScreenChange: Subject<object> = new Subject<object>();
  initCaptureChange: Subject<object> = new Subject<object>();
  openSideNavChange: Subject<object> = new Subject<object>();
  selectedSiteChange: Subject<any> = new Subject<any>();
  rmRefreshEvent: Subject<any> = new Subject<any>();
  selectedNode = {};
  networkDeviceReloadChange: Subject<any> = new Subject<any>();
  addCompanyChange: Subject<any> = new Subject<any>();
  addSiteChange: Subject<any> = new Subject<any>();
  checkNode = {};
  timeZoneList: any;
  currentTimezone: any;
  unauth: any;
  currentCompany: any;
  ccId: any;
  modalHolder: string[] = [];
  currentAssessment: any;
  currentCompanyView: string;
  eulaContent = '';
  constructor(public baseRequestService: BaseRequestService, private toast: MyToastrService) { }

  getLastDay(day: number, today?: any): any {
    if (!day) { return new Date().toLocaleDateString('en-CA')+'T23:59:00'; }
    const date = (today) ? new Date(today) : new Date();
    const nthday = new Date(date.getFullYear(),date.getMonth(),date.getDate() + day);
    if (new Date(nthday).getTime() > new Date().getTime()) {
        return new Date().toLocaleDateString('en-CA')+'T23:59:00';
    }
    return new Date(nthday).toLocaleDateString('en-CA')+'T23:59:00';
  }


  validateDateRange(from: any, to: any, days: number): any{
    if ((from !== null && new Date(from).getTime() > new Date(new Date().toLocaleDateString('en-CA')+'T23:59:59').getTime())
        || ( to !==  null && new Date(to).getTime() > new Date(new Date().toLocaleDateString('en-CA')+'T23:59:59').getTime())){
      return {status: 'Error' , msg: 'Invalid date selection'};
    }
    const date = (from) ? new Date(from) : new Date();
    const nthday = new Date(date.getFullYear(),date.getMonth(),date.getDate() + days);
    if (to !== null && new Date(to).getTime() > new Date(nthday).getTime()) {
      return {status: 'Error' , msg: `Invalid date selection. Please select date range between ${days} days.`};
    }
    if (from !== null && to !== null  && new Date(from).getTime() > new Date(to).getTime()) {
      return {status: 'Error' , msg: 'From date can not be greater than To date.'};
    }
    return {status: 'Success'};
  }

  isPrivateIP(ip: string): boolean {
    const parts = ip.split('.');
    return parts[0] === '10' ||
      (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) ||
      (parts[0] === '192' && parts[1] === '168');
  }

  isZeroOctetIP(ip: string): boolean {
    const parts = ip.split('.');
    if (parts.length !== 4) {
      return true;
    }
    return (parts[3] === '0');
  }

  isUuid(uuid: string): boolean {
    if (uuid) {
      // @ts-ignore
      return ( uuid.match(/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/) ?.length > 0 );
    } else {
      return false;
    }
  }

  nl2br(str: string): string{
   return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  compare(a: number | string, b: number | string, isAsc: boolean): any {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  buildQuery(data: { [x: string]: any }): any {
    const baseQuery = {query: {bool: {must: []}}};
    this.Objectkeys(data).forEach((obj) => {
      const tmpObj = {match: {}};
      // @ts-ignore
      tmpObj.match[obj] = data[obj];
      // @ts-ignore
      baseQuery.query.bool.must.push(tmpObj);
    });
    return baseQuery;
  }
  onlyUnique(value: any, index: any, self: string | any[]): boolean {
      return self.indexOf(value) === index;
  }
  reloadDevice(deviceId: any): void {
    this.networkDeviceReloadChange.next(deviceId);
  }

  percentCalculator(x: number, y: number): number {
    return Math.floor((y / x) * 100);
  }

  copyClip(cmd: string): void {
    const el = document.createElement('textarea');
    el.value = cmd;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toast.sToast('success', 'Copied to clipboard');
  }

  getDateDiff(from: any, to: any): any {
    const diffd = to.getTime() - from.getTime();
    return diffd / (1000 * 3600 * 24);
  }

  getTimeZone(): void {
    const offset = (new Date()).getTimezoneOffset();
    const timezones = {
    '-12': 'Pacific/Kwajalein',
    '-11': 'Pacific/Samoa',
    '-10': 'Pacific/Honolulu',
    '-9': 'America/Juneau',
    '-8': 'America/Los_Angeles',
    '-7': 'America/Denver',
    '-6': 'America/Mexico_City',
    '-5': 'America/New_York',
    '-4': 'America/Caracas',
    '-3.5': 'America/St_Johns',
    '-3': 'America/Argentina/Buenos_Aires',
    '-2': 'Atlantic/Azores',
    '-1': 'Atlantic/Azores',
    '0': 'Europe/London',
    '1': 'Europe/Paris',
    '2': 'Europe/Helsinki',
    '3': 'Europe/Moscow',
    '3.5': 'Asia/Tehran',
    '4': 'Asia/Baku',
    '4.5': 'Asia/Kabul',
    '5': 'Asia/Karachi',
    '5.5': 'Asia/Calcutta',
    '6': 'Asia/Colombo',
    '7': 'Asia/Bangkok',
    '8': 'Asia/Singapore',
    '9': 'Asia/Tokyo',
    '9.5': 'Australia/Darwin',
    '10': 'Pacific/Guam',
    '11': 'Asia/Magadan',
    '12': 'Asia/Kamchatka'
  };
    // @ts-ignore
    this.currentTimezone = timezones[-offset / 60];
    const timeZone = localStorage.getItem('_timeZones');
    if (timeZone && timeZone.length > 0 ) {
      this.timeZoneList = JSON.parse(timeZone);
    }
  }

  addCompany(name: any): void {
    this.addCompanyChange.next(name);
  }
  addSite(ele: any, name: any): void {
    ele.action = name.action;
    this.addSiteChange.next(ele);
  }

  getPatchingStatus(companyId?: any): void {
    this.patchingStatus = false;
    this.isEulaAccepted = false;
    const data: any = {};
    if (companyId) { data.companyId = companyId; }
    this.baseRequestService.doRequest(this.baseRequestService.API +'/api/cyberpatching/getPatchingStatus', 'post', data)
      .subscribe((result: any) => {
        if (result) {
          this.patchingStatus = result.patchingStatus;
          this.isEulaAccepted = result.isEulaAccepted;
        } else {
          this.toast.sToast('error', result[1]);
        }
    });
  }

  async sortFn(arr: any, key?: string): Promise<any> {
    if (key) {
      return await arr.sort((a: any, b: any) => {
        const c = a[key]; const d = b[key];
        if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
      });
    }
    return await arr.sort((c: any, d: any) => {
      if (c < d) { return -1; } else if (c > d) { return 1; } else { return 0; }
    });
  }
}
