import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import {AuthenticationService} from '../../../_services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    /**
     * Constructor
     *
     */

    guardHash: any = {
        vms_admin: ['/pages/dealers', '/pages/apos', '/pages/transactions', '/pages/admin-config',  '/pages/support', '/pages/apos-ro-upload', '/pages/users', '/pages/createadmin', 'sign-out'],
        vms_ravendor: ['/pages/dealers', '/pages/apos', '/pages/apos-upload', '/sign-out'],
        ro_dealer: ['/pages/apos', '/pages/dealer-transactions', '/pages/change-password','/pages/manager-pin-generation', '/pages/support', '/sign-out' ],
        vms_support: ['/pages/transactions', '/pages/support', '/sign-out', ],
        vms_territory: ['/pages/dealers', '/pages/apos', '/pages/transactions', '/pages/support', '/pages/apos-ro-upload', '/sign-out'],
        vms_state: ['/pages/dealers', '/pages/apos', '/pages/transactions',  '/sign-out'],
        vms_region: ['/pages/dealers', '/pages/apos', '/pages/transactions',  '/sign-out'],
        vms_finance: [ '/pages/transactions', '/pages/support', '/sign-out']
    };
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private authenticationService: AuthenticationService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
        /*if (!this.authenticationService.isAuthenticated) {
          if ( window.location.pathname !== '' && window.location.pathname !== '/' && window.location.pathname !== '/sign-in') {
             }
          this._router.navigateByUrl('/sign-in').then(() => console.log('redirecting to login'));
          return false;
        }
        // TODO additional logic will be coming in future
        return this.authenticationService.isAuthenticated;*/
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): Observable<boolean>
    {
        // Check the authentication status
        return this._authService.check()
           .pipe(
               switchMap((authenticated) => {

                   // If the user is not authenticated...
                   if ( !authenticated )
                   {
                       // Redirect to the sign-in page
                       const a = localStorage.getItem('ia');
                       if (a === 's') {
                           this._router.navigate(['/pages/adminlogin']);
                       } else {
                           this._router.navigate(['/']);
                       }
                       // , {queryParams: {redirectURL}}

                       // Prevent the access
                       return of(false);
                   } else {
                       console.log(this._authService._logginType, redirectURL);
                       if (this._authService._logginType && this.guardHash[this._authService._logginType]
                           && this.guardHash[this._authService._logginType].indexOf(redirectURL) === -1 && redirectURL !== '/') {
                           this._router.navigateByUrl(this.guardHash[this._authService._logginType][0]);
                            return of(false);
                       }
                   }
                   // Allow the access
                   return of(true);
               })
           );
    }
}
