import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe,
    ArrayOrderByPipe,
    FormatTrafficUnitsPipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe,
    MacFilterPipe, MaskFilterPipe,
    AssessmentDateFilterPipe,
    SplitPipe,
    FilterPipe,
    ArrayToStrEllipsisPipe,
    SearchPipe,
    SafeHTMLPipe,
    TimeAgoPipe,
    ArraySortPipe,
    EllipsisPipe,
    ShortNumberPipe,
    UtcToLocale,
    FileNameFilterPipe,
    MaskPasswordFilterPipe,
    ArrayToObjWithEllipsisPipe,
    GMTToLocale
} from './app.filter.pipe';

@NgModule({
  declarations: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, MaskFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, EllipsisPipe, ShortNumberPipe, UtcToLocale,
    FileNameFilterPipe, MaskPasswordFilterPipe, ArrayToObjWithEllipsisPipe,
    DateAgoPipe, GMTToLocale,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe, FilterPipe
  ],
  imports: [CommonModule],
    exports: [
        CamelToHumanPipe,
        DaysHoursSecondsPipe,
        BytesConvertFilterPipe,
        EpochToDateFilterPipe,
        StrToMacPipe,
        SafePipe, MacFilterPipe, MaskFilterPipe, ArrayOrderByPipe,
        AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
        FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe,
        FileNameFilterPipe, MaskPasswordFilterPipe, ArrayToObjWithEllipsisPipe,
        DateAgoPipe, GMTToLocale,
        FormatCellPipe,
        UniqueFilterPipe,
        ArrayToStrPipe,
        TimeFormat,
        DateFormat,
        DateAndTimeFormat,
        CveToDateFilterPipe,
        FilterPipe, EllipsisPipe, ShortNumberPipe, UtcToLocale
    ],
  providers: [DatePipe, FilterPipe, DateAgoPipe, GMTToLocale, BytesConvertFilterPipe, ArrayOrderByPipe, SearchPipe, MaskFilterPipe]
})
export class AppFilterPipeModule {
}
