import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  eM: any; eT: any = 'error';
  lM: any;
  constructor() { }
}
