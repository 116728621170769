import {  Injectable, Inject, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class MyToastrService {
  constructor(@Inject(Injector) private injector: Injector, private eS: ErrorService) { }
  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }
  sToast(type: string, message: string, title?: string | null): void {
    // @ts-ignore
    // this._toastrService[type](message, title);
    this.eS.eM = message; this.eS.eT = type;
  }
}
