<link rel="preconnect" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap" rel="stylesheet">
<div class="flex flex-row items-center w-full" style="width: 100vw !important;">
    <div class="spacer">&nbsp;</div>
    <div class="flex flex row body" *ngIf="!fd">
        <mat-card class="m-4 border m-4 border border-gray-200 rounded-lg mat-elevation-z8">
            <img [src]="filedownload" alt="fd">
        </mat-card>
    </div>
    <div class="flex flex-col flex-auto body" *ngIf="fd">
        <div class="flex flex-wrap w-full max-w-screen-2xl mx-auto p-6 md:p-8 text-center"  *ngIf="!message || !message.length">
            <div class="flex flex-col items-center w-full -mt-2" *ngIf="qr.qrCode && qr.qrCode.length">
                <img [src]="logo" style="
                    width: 70px;
                    position: absolute;
                    top: 149px;
                ">
                <ngx-qrcode-styling id="qr-code" class="qr-code" [config]="config" ></ngx-qrcode-styling>
                <div class="spacer"></div>
                <div class="px-3 w-full">
                    <h1 class="voucher-amt">₹{{ qr.amount }}</h1>
                    <h1 class="font-bold">Voucher Amount</h1>
                    <hr class="my-5">
                    <h1 class="">Please use before</h1>
                    <h1 class=" font-bold">{{ qr.expiryTime }}, {{ qr.expiryDate }}</h1>
                    <h1 class="">at select</h1>
                    <h1 class=" font-bold">IOCL petrol pumps</h1>
                    <h1 class="">to pay for fuel</h1>
                </div>
                <div class="spacer" *ngIf="qr.qrCode && qr.qrCode.length"></div>
                <div class="w-full"><hr class="my-5 w-full" *ngIf="qr.qrCode && qr.qrCode.length"></div>
                <div class="flex w-full" *ngIf="qr.qrCode && qr.qrCode.length">
                    <h1 class="text-md text-gray-400 -mt-2">By using IOCL’s UnifiedBilling voucher, I have consented to my mobile number being used for better customer support by IOCL.</h1>
                </div>
            </div>
        </div>
        <div *ngIf="message && message.length" class="text-center">
            <h1 class="pt-4 font-extrabold text-2xl">{{message}}</h1>
        </div>
    </div>
    <div class="spacer">&nbsp;</div>
</div>

