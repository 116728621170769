import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BaseRequestService} from '../_services/base.service';
import {LoaderService} from '../_services/loader.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-r',
  templateUrl: './r.component.html',
  styleUrls: ['./r.component.scss']
})
export class RComponent implements OnInit {
  rcode: any; message: any; receipt: any;
  constructor(private params: ActivatedRoute, private baseRequest: BaseRequestService,
              private loaderService: LoaderService) { }

  ngOnInit(): void {
      this.rcode = this.params.snapshot.paramMap.get('rcode');
      if (this.rcode) { this.validateVCode(); }
  }

  validateVCode(): void {
      this.loaderService.display(true);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.baseRequest.doRequest(this.baseRequest.API +'/api/qr_code/download_receipt', 'post', {qr_code: this.rcode}).subscribe((result: any) => {
          this.loaderService.display(false);
          if (result.status === 'ok') {
              this.receipt = result.msg;
              setTimeout(() => {
                window.open(`https://unifiedbilling.mycybercns.com/api/download_receipt?qr_code=${this.rcode}`, '_blank');
              }, 1000);
          } else {
              this.message = 'Receipt Not Found!';
          }
      });
  }

  exportPdf(): void {
    /* const data = document.getElementById('receiptHtml');
    html2canvas(data).then((canvas) => {
        const docWidth = 208;
        const docHeight = canvas.height * docWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const doc = new jsPDF('p', 'mm', 'a4');
        const position = 0;
        doc.addImage(contentDataURL, 'PNG', 0, position, docWidth, docHeight);
        doc.save('receipt.pdf');
    }); */
  }
}
