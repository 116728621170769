<!-- Button -->
<button
    mat-button
    [matMenuTriggerFor]="userActions">
    <span class="relative flex flex-row items-center justify-center">
        <img
            class="w-7 h-7 rounded-full"
            *ngIf="showAvatar && user && user.avatar"
            [src]="user.avatar">
        <mat-icon
            *ngIf="!showAvatar || !user || !user.avatar" class="text-white"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <!-- <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"
        ></span> -->
        <span class="mx-1 hidden lg:flex" *ngIf="user && user.name">{{user.name}}</span>
        <mat-icon
            *ngIf="!showAvatar || !user || !user.avatar" class="text-white"
            [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <!-- <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span class="text-xs">Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
            <span class="mt-1.5 text-md font-medium">{{role}}</span>
        </span>
    </button> -->
    <!--<mat-divider class="my-2" *ngIf="isRAVendor || isAdmin"></mat-divider>-->
    <!--<button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>-->

    <button mat-menu-item [routerLink]="'/pages/apos-upload'" *ngIf="isRAVendor">
        <!--<mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>-->
        <img class="mr-2" style="width: 17px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAVZJREFUOBGdlE1SwkAQhftN4lpXrrkBWJK1lGC5NDcwkgt4BG4gHgAyHsG1gLoOVWTrTm7AXpO2hwjmtyrYVZOavJ7+Mj3dEzhXXofYCoioI2NvzLz5jpOz6E1/7sWaiU2J0gxqgemJwX8BIGoCMFybgLYBhPOJZ4T/mDJBuR3UUDo970RGq8q9hVQ5itqRZY1tS62cy6FX9DWGkIrHckxrUgi6g+FDFtQYEr7o6CtOekz0DMJ9d+C/mhQNzM4Sz/u+C3A7q5XnvGLiYyL0LEt9SItc5yAAefKVm3JgUZHEjIFPmZWbgyxnEzf11j+zzQnGo7TGKAepD009Jl1OOCDZLhK+CxdTbTyNIdvSggJmrIHYDRc6StEHQFiRVILf5T650VxvdoCDdrKcTccSYEbJfvsEF7ual1Y0EOD0fS0HdVtce8ivYFtwc2iSc6sIkhRGRa3q/QfN2noZnd5tXQAAAABJRU5ErkJggg==">
        <span>POS Upload</span>
    </button>
    <button mat-menu-item [routerLink]="'/pages/apos-ro-upload'" *ngIf="isAdmin">
        <img class="mr-2" style="width: 17px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAVZJREFUOBGdlE1SwkAQhftN4lpXrrkBWJK1lGC5NDcwkgt4BG4gHgAyHsG1gLoOVWTrTm7AXpO2hwjmtyrYVZOavJ7+Mj3dEzhXXofYCoioI2NvzLz5jpOz6E1/7sWaiU2J0gxqgemJwX8BIGoCMFybgLYBhPOJZ4T/mDJBuR3UUDo970RGq8q9hVQ5itqRZY1tS62cy6FX9DWGkIrHckxrUgi6g+FDFtQYEr7o6CtOekz0DMJ9d+C/mhQNzM4Sz/u+C3A7q5XnvGLiYyL0LEt9SItc5yAAefKVm3JgUZHEjIFPmZWbgyxnEzf11j+zzQnGo7TGKAepD009Jl1OOCDZLhK+CxdTbTyNIdvSggJmrIHYDRc6StEHQFiRVILf5T650VxvdoCDdrKcTccSYEbJfvsEF7ual1Y0EOD0fS0HdVtce8ivYFtwc2iSc6sIkhRGRa3q/QfN2noZnd5tXQAAAABJRU5ErkJggg==">
        <span>POS and RO Upload</span>
    </button>
    <button mat-menu-item [routerLink]="'/pages/createadmin'" *ngIf="isAdmin && !isTerritory">
        <img class="mr-2" style="width: 17px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAVZJREFUOBGdlE1SwkAQhftN4lpXrrkBWJK1lGC5NDcwkgt4BG4gHgAyHsG1gLoOVWTrTm7AXpO2hwjmtyrYVZOavJ7+Mj3dEzhXXofYCoioI2NvzLz5jpOz6E1/7sWaiU2J0gxqgemJwX8BIGoCMFybgLYBhPOJZ4T/mDJBuR3UUDo970RGq8q9hVQ5itqRZY1tS62cy6FX9DWGkIrHckxrUgi6g+FDFtQYEr7o6CtOekz0DMJ9d+C/mhQNzM4Sz/u+C3A7q5XnvGLiYyL0LEt9SItc5yAAefKVm3JgUZHEjIFPmZWbgyxnEzf11j+zzQnGo7TGKAepD009Jl1OOCDZLhK+CxdTbTyNIdvSggJmrIHYDRc6StEHQFiRVILf5T650VxvdoCDdrKcTccSYEbJfvsEF7ual1Y0EOD0fS0HdVtce8ivYFtwc2iSc6sIkhRGRa3q/QfN2noZnd5tXQAAAABJRU5ErkJggg==">
        <span>User Management</span>
    </button>
    <button mat-menu-item [routerLink]="'/pages/change-password'" *ngIf="isDealer">
        <img class="mr-2" style="width: 17px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAVZJREFUOBGdlE1SwkAQhftN4lpXrrkBWJK1lGC5NDcwkgt4BG4gHgAyHsG1gLoOVWTrTm7AXpO2hwjmtyrYVZOavJ7+Mj3dEzhXXofYCoioI2NvzLz5jpOz6E1/7sWaiU2J0gxqgemJwX8BIGoCMFybgLYBhPOJZ4T/mDJBuR3UUDo970RGq8q9hVQ5itqRZY1tS62cy6FX9DWGkIrHckxrUgi6g+FDFtQYEr7o6CtOekz0DMJ9d+C/mhQNzM4Sz/u+C3A7q5XnvGLiYyL0LEt9SItc5yAAefKVm3JgUZHEjIFPmZWbgyxnEzf11j+zzQnGo7TGKAepD009Jl1OOCDZLhK+CxdTbTyNIdvSggJmrIHYDRc6StEHQFiRVILf5T650VxvdoCDdrKcTccSYEbJfvsEF7ual1Y0EOD0fS0HdVtce8ivYFtwc2iSc6sIkhRGRa3q/QfN2noZnd5tXQAAAABJRU5ErkJggg==">
        <!--<mat-icon [svgIcon]="'heroicons_outline:identification'"></mat-icon>-->
        <span>Change Password</span>
    </button>
    <!--<button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button>-->
    <!--<mat-divider class="my-2" *ngIf="isDealer || isAdmin"></mat-divider>-->
    <button
        mat-menu-item
        (click)="signOut()">
        <!--<mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>-->
        <img class="mr-2" style="width: 17px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAVZJREFUOBGdlE1SwkAQhftN4lpXrrkBWJK1lGC5NDcwkgt4BG4gHgAyHsG1gLoOVWTrTm7AXpO2hwjmtyrYVZOavJ7+Mj3dEzhXXofYCoioI2NvzLz5jpOz6E1/7sWaiU2J0gxqgemJwX8BIGoCMFybgLYBhPOJZ4T/mDJBuR3UUDo970RGq8q9hVQ5itqRZY1tS62cy6FX9DWGkIrHckxrUgi6g+FDFtQYEr7o6CtOekz0DMJ9d+C/mhQNzM4Sz/u+C3A7q5XnvGLiYyL0LEt9SItc5yAAefKVm3JgUZHEjIFPmZWbgyxnEzf11j+zzQnGo7TGKAepD009Jl1OOCDZLhK+CxdTbTyNIdvSggJmrIHYDRc6StEHQFiRVILf5T650VxvdoCDdrKcTccSYEbJfvsEF7ual1Y0EOD0fS0HdVtce8ivYFtwc2iSc6sIkhRGRa3q/QfN2noZnd5tXQAAAABJRU5ErkJggg==">
        <span>Log Out</span>
    </button>
</mat-menu>

<!--<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>-->
