import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    guardHash: any = {
        vms_admin: ['/pages/dealers', '/pages/apos', '/pages/transactions', '/pages/admin-config',  '/pages/support', '/pages/apos-ro-upload', '/pages/users', '/pages/createadmin', 'sign-out'],
        vms_ravendor: ['/pages/dealers', '/pages/apos', '/pages/apos-upload', '/sign-out'],
        ro_dealer: ['/pages/apos', '/pages/dealer-transactions', '/pages/change-password','/pages/manager-pin-generation', '/pages/support', '/sign-out' ],
        vms_support: ['/pages/transactions', '/pages/support', '/sign-out', ],
        vms_territory: ['/pages/dealers', '/pages/apos', '/pages/transactions', '/pages/support', '/pages/apos-ro-upload', '/sign-out'],
        vms_state: ['/pages/dealers', '/pages/apos', '/pages/transactions',  '/sign-out'],
        vms_region: ['/pages/dealers', '/pages/apos', '/pages/transactions',  '/sign-out'],
        vms_finance: [ '/pages/transactions', '/pages/support', '/sign-out']
    };
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check();
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check();
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    private _check(): Observable<boolean>
    {
        // Check the authentication status
        return this._authService.check()
                   .pipe(
                       switchMap((authenticated) => {

                           // If the user is authenticated...
                           if ( authenticated )
                           {
                               // Redirect to the root
                               // this._router.navigate(['/']);

                               if (this._authService._logginType && this.guardHash[this._authService._logginType]) {
                                   this._router.navigateByUrl(this.guardHash[this._authService._logginType][0]);
                                    return of(false);
                               }

                               // Prevent the access
                               return of(false);
                           }

                           // Allow the access
                           return of(true);
                       })
                   );
    }
}
