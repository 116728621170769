import {HttpClient, HttpHeaders, HttpParams, HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import {Router} from "@angular/router";
import {MyToastrService} from "./toastr.service";

interface NetaResponse {
  status: string;
  msg: any;
}

@Injectable({providedIn: 'root'})
export class BaseRequestService {
  roleHash: any = ['dsm', 'ro_manager', 'ro_dealer', 'admin', 'ra_vendor', 'vms_support', 'vms_territory', 'vms_state', 'na', 'vms_region'];
  cisv8: any = [];
  cyberLabel: any;
  API: any = '';
  isAssessment = false;
  showCompany = true;
  showAssessment = false;
  scoreCompanyHash: any = {};
  verifyWindow: any;
  o365window: any;
  authHeader = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': btoa(window.location.host)});
  currentSite: any; currentCompany: any;
  resources: any = {};
  companyId: any;
  helpLinks: any;
  enckey: any;
  overlayLoadingTemplate =
    '<span class="">' +
    '<i class="fa fa-spinner fa-spin"></i> Please wait while your rows are loading</span>';
  companyList: any = [];
  constructor(readonly httpClient: HttpClient, private titleService: Title, private router: Router,
              private toast: MyToastrService,) {
  }

  public downloadCSV(data: {}[], title: string, options?: any): void {
    if (options) {
      // tslint:disable-next-line:no-unused-expression
      new ngxCsv(data, title, options);
    } else {
      // tslint:disable-next-line:no-unused-expression
      new ngxCsv(data, title, { headers: Object.keys(data[0])});
    }
  }

  public redirect(url: string): any {
      this.router.navigateByUrl(url);
  }

  public upload(url: string, data: any): any {
    return this.httpClient.post<any>(url, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          // @ts-ignore
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    }));
  }

  keypress(event:any): any {
    return false;
  }
  public getClientData(): any {
    return this.httpClient.get('https://extreme-ip-lookup.com/json/');
  }

  public nonce(): number | string {
      let val = '';
      const hex = 'abcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 16; i++) { val += hex.charAt(Math.floor(Math.random() * hex.length)); }
      return val;
  }

  public unsafePublish(topic: string, message: string): void {
    // this._mqttService.unsafePublish(topic, message, {qos: 0, retain: false});
  }

  toHttpParams(obj: any): any {
    const params = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const val = obj[key];
        if (val !== null && val !== undefined) {
          if (typeof val === 'object') {
            // @ts-ignore
            params[key] = JSON.stringify(val);
          } else {
            // @ts-ignore
            params[key] = val.toString();
          }
        }
      }
    }
    return params;
  }

  validator(key: string, event: any, min: any, max: any, local?: boolean): void {
      try {
          const value = event.target.value;
          switch (key) {
              case 'utr': {
                  if (value.length < 12  && value.length) { // && value.length !== 20
                      this.toast.sToast('error', 'UTR No. must be 12 characters or more');
                  } else {
                      this.toast.sToast('error', '');
                  }
                  break;
              }
              case 'mobile': {
                  if (value.length !== 10 && value.length) {
                      this.toast.sToast('error', 'Mobile Number must be 10 digits');
                  } else {
                      this.toast.sToast('error', '');
                  }
                  break;
              }
              case 'sap': {
                  if (value.length !== 6 && value.length) {
                      this.toast.sToast('error', 'sapCcId must be 6 digits');
                  } else {
                      this.toast.sToast('error', '');
                  }
                  break;
              }
              case 'voucher': {
                  if (value.length !== 6 && value.length) {
                      this.toast.sToast('error', 'Voucher Code must be 6 digits');
                  } else {
                      this.toast.sToast('error', '');
                  }
                  break;
              }
              default: {
                  this.toast.sToast('error', '');
              }
          }
      } catch (e) {
          console.log(e);
      }
    }

  deleteRequest(endPointUrl: string): any {
    return this.httpClient.delete<NetaResponse>(`${endPointUrl}`).pipe(map(response => this.handleResponse(response)));
  }

  doRequest(endPointUrl: string,
            method: string,
            data?: any,
            params?: any,
            headers?: HttpHeaders, hashOptions?: any): any {
    const httpOptions = {
      headers: headers
        ? headers
        : new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      body: JSON.stringify(data),
      params,
    };
    if (params) {
      httpOptions.params = new HttpParams({
          fromObject: this.toHttpParams(params)
        }
      );
    }
    // @ts-ignore
    httpOptions.headers.hashOptions = hashOptions ? hashOptions : {isLoading: false};
    return this.httpClient
      .request<NetaResponse>(method, `${endPointUrl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }
  getSnakeCaseName(camelCase: string): string {
      return camelCase.replace( /([A-Z])/g, '_$1').toLowerCase().replace(/^_(.*)/g, '$1');
  }

  private handleResponse(response: NetaResponse): NetaResponse {
    return response;
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
