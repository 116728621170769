<!-- Loading bar -->
<!--<fuse-loading-bar></fuse-loading-bar>-->

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark unified-billing-bg print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-2 px-4">
                <img
                    class="" style="width:3rem"
                    [src]="logo"
                    alt="Unified Billing">
                    <span class="app-title">UnifiedBilling</span>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none
     dark:border-b bg-card dark:bg-transparent print:hidden unified-billing-bg" style="height: 65px !important;">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="dark:hidden" style="width:3rem"
                        [src]="logo" alt="Unified Billing">
                    <img
                        class="hidden dark:flex" style="width:3rem" alt="Unified Billing"
                        [src]="logo">
                </div>
                <img
                    class="flex lg:hidden" style="width:3rem"
                    [src]="logo">
                    <span class="app-title">UnifiedBilling</span>
            </div>
            <div class="spacer"></div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img class="dark:hidden" style="width:3rem"
                        [src]="logo" alt="Unified Billing">
                    <img
                        class="hidden dark:flex" style="width:3rem" alt="Unified Billing"
                        [src]="logo">
                </div>
                <img
                    class="flex lg:hidden" style="width:3rem"
                    [src]="logo">
                <span class="app-title">UnifiedBilling</span>
            </div>
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-white"></mat-icon>
            </button>
        </ng-container>
        <div class="spacer"></div>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>-->
            <!-- <fuse-fullscreen class="hidden md:block text-white"></fuse-fullscreen> -->
            <!--<search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>-->
            <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>-->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <p *ngIf="eS.eM" class="text-md px-4 py-1 absolute font-bold text-red-500 top-[67px] bg-transparent z-[9]">{{eS.eM}}</p>
        <router-outlet [ngClass]="{'mt-5': isScreenSmall}" *ngIf="true"></router-outlet>
    </div>
    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Unified Billing &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
