/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const dealerNavigation: FuseNavigationItem[] = [
    {
        id   : 'apos',
        title: 'POS',
        type : 'basic',
        icon : 'heroicons_outline:device-mobile',
        link : '/pages/apos'
    },
    {
        id   : 'transactions',
        title: 'TRANSACTIONS',
        type : 'basic',
        icon : 'heroicons_outline:currency-rupee',
        link : '/pages/transactions'
    },
    {
        id   : 'dealer-config',
        title: 'CONFIGURATION',
        type : 'basic',
        icon : 'heroicons_outline:cog',
        link : '/pages/admin-config'
    },
    {
        id   : 'support',
        title: 'SUPPORT',
        type : 'basic',
        icon : 'heroicons_outline:badge-check',
        link : '/pages/support'
    },
];

export const adminNavigation: FuseNavigationItem[] = [
    {
        id   : 'dealers',
        title: 'CUSTOMERS',
        type : 'basic',
        icon : 'heroicons_outline:office-building',
        link : '/pages/dealers'
    },
    {
        id   : 'apos',
        title: 'POS',
        type : 'basic',
        icon : 'heroicons_outline:device-mobile',
        link : '/pages/apos'
    },
    {
        id   : 'transactions',
        title: 'TRANSACTIONS',
        type : 'basic',
        icon : 'heroicons_outline:currency-rupee',
        link : '/pages/transactions'
    },
    {
        id   : 'admin-config',
        title: 'CONFIGURATION',
        type : 'basic',
        icon : 'heroicons_outline:cog',
        link : '/pages/admin-config'
    },
    {
        id   : 'support',
        title: 'SUPPORT',
        type : 'basic',
        icon : 'heroicons_outline:badge-check',
        link : '/pages/support'
    },
    {
        id   : 'users',
        title: 'USERS',
        type : 'basic',
        icon : 'heroicons_outline:users',
        link : '/pages/users'
    }
];

export const defaultNavigation: FuseNavigationItem[] = adminNavigation;
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Pages C',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/pages'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Pages F',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/pages'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = adminNavigation;
